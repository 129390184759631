import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { PriorityBlock } from 'components/PriorityBlock';
import { RadioGroup } from 'components/RadioGroup';
import { Create } from 'core/modals/Create';
import { Priority } from 'core/types';
import {
  fetchSystemPrioritiesRequest,
  resetSystemPriorities,
} from 'features/Systems/ducks/actions';
import { getPriorityByTitleRadioTabs } from 'features/Systems/ducks/selectors';
import { editTicketPriority } from 'features/Ticket/ducks/ticket/actions';
import { getTicket } from 'features/Ticket/ducks/ticket/selectors';
import { getPriorityByTitle } from 'features/Ticket/utils';

import styles from './TicketPriorityEdit.module.scss';

interface Props {
  isModal: boolean;
  toggleModal(): void;
}

export const TicketPriorityEdit: React.FC<Props> = ({
  isModal,
  toggleModal,
}) => {
  const closeModal = () => {
    toggleModal();
  };

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, isValid },
  } = useForm<{ priority: Priority }>({
    mode: 'onChange',
  });

  const { priority } = watch();

  const radioPriorityTabs = useSelector(getPriorityByTitleRadioTabs);
  const ticket = useSelector(getTicket);

  const dispatch = useDispatch();

  useEffect(() => {
    if (ticket?.systemId?.key && isModal) {
      dispatch(fetchSystemPrioritiesRequest(ticket?.systemId?.key));
    }
  }, [ticket?.systemId?.key, isModal]);

  useEffect(() => {
    return () => {
      dispatch(resetSystemPriorities());
    };
  }, []);

  useEffect(() => {
    if (ticket?.priority) {
      setValue('priority', ticket?.priority);
    }
  }, [ticket, isModal]);

  const priorityRadioOptions = register('priority', { required: true });

  const formSubmitHandler = handleSubmit((data) => {
    if (ticket?.id) {
      dispatch(
        editTicketPriority({ priority: data.priority, ticketId: ticket?.id })
      );
    }
    closeModal();
    reset();
  });

  const renderRadioGroupComponent = (title?: string) => {
    return (
      <PriorityBlock
        priority={getPriorityByTitle(title)}
        className={styles.ticketPriorityEdit__priorityBlock}
      />
    );
  };

  return (
    <>
      <Create
        toggleModal={toggleModal}
        isModal={isModal && !ticket?.isDeleted}
        title="Приоритет"
        createTitle="Применить"
        cancelTitle="Отменить"
        onSubmit={formSubmitHandler}
        disabledSubmit={!isDirty || !isValid || priority === ticket?.priority}
        subModalText="изменение приоритета"
      >
        <RadioGroup
          {...priorityRadioOptions}
          items={radioPriorityTabs}
          renderComponent={renderRadioGroupComponent}
        />
      </Create>
    </>
  );
};
