import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getIsMobile, getIsMobileSmall } from 'core/ducks/selectors';
import { Priority, Status, TicketStatus } from 'core/types';
import { getIsClient } from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import { REOPEN_STATUS } from 'features/Ticket/constants';
import {
  getIsAccessedTicketAction,
  getIsSystemIntegratedWithJira,
  getJiraUrl,
  getLoading,
  getNextStatuses,
  getStatuses,
  getTicket,
} from 'features/Ticket/ducks/ticket/selectors';
import { useIsAccessToChangeStatus } from 'features/Ticket/hooks/useIsAccessToChangeStatus';
import { CommentType } from 'features/Ticket/types';

import {
  changeStatus,
  deleteTicketRequest,
  reopenTicketRequest,
} from '../../../ducks/ticket/actions';

const getCommentType = (status: Status | null) => {
  const defaultStatus = status?.defaultStatus;
  if (defaultStatus === TicketStatus.WAITING_INFO) {
    return CommentType.INFO_REQUEST;
  }
  if (defaultStatus === TicketStatus.PENDING_CLOSURE) {
    return CommentType.CLOSE_REQUEST;
  }
  if (defaultStatus === TicketStatus.WORK) {
    return CommentType.RETURN_TO_WORK;
  }
  return CommentType.CHANGE_STATUS;
};

export const useTicketLayout = () => {
  const dispatch = useDispatch();

  const ticket = useSelector(getTicket);
  const loading = useSelector(getLoading);
  const isAccessToEditPriority = useSelector(
    getIsAccessedTicketAction(ActionList.EditTicketPriority)
  );
  const isClient = useSelector(getIsClient);
  const jiraUrl = useSelector(getJiraUrl);
  const isSystemIntegratedWithJira = useSelector(getIsSystemIntegratedWithJira);
  const nextStatuses = useSelector(getNextStatuses);
  const statuses = useSelector(getStatuses);

  const isAccessToDeleteTicket = useSelector(
    getIsAccessedTicketAction(ActionList.DeleteTicket)
  );
  const isAccessToAssigningTicketToSpecialist = useSelector(
    getIsAccessedTicketAction(ActionList.AssigningTicketToSpecialist)
  );
  const isAccessToReopenTicket = useSelector(
    getIsAccessedTicketAction(ActionList.ReopenTicket)
  );
  const isMobile = useSelector(getIsMobile);
  const isMobileSmall = useSelector(getIsMobileSmall);
  const isAccessToChangeStatus = useIsAccessToChangeStatus();
  const isAccessViewingAllTickets = useSelector(
    getIsAccessedTicketAction(ActionList.ViewingAllTickets)
  );
  const hasReopenStatus = !!statuses?.some(
    (status) => status.value === 'Переоткрыт'
  );

  const isMobileAll = isMobile || isMobileSmall;

  const ticketPriority = ticket?.priority || Priority.NONE;
  const ticketStatus = ticket?.customStatus?.defaultStatus;
  const isTickedClosed =
    ticket?.customStatus?.defaultStatus === TicketStatus.CLOSE;
  const isShowTicketClosedButton =
    ticket?.customStatus?.defaultStatus !== TicketStatus.CLOSE &&
    isAccessViewingAllTickets;

  const isTicketDeleted = ticket?.isDeleted;
  const isReopenTicket =
    isAccessToReopenTicket && isTickedClosed && hasReopenStatus;

  const [isModal, setIsModal] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isShowCloseModal, setIsShowCloseModal] = useState(false);
  const [isShowModal, setIsShowModal] = useState<boolean>(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [isEditStatusModalOpen, setIsEditStatusModalOpen] =
    useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<Status | null>(null);

  const reopenStatus = nextStatus?.defaultStatus === TicketStatus.REOPEN;

  const toggleIsEditStatusModalOpen = () => {
    setIsEditStatusModalOpen((prevState) => !prevState);
  };

  const toggleIsPopoverOpen = () => {
    setIsPopoverOpen((prevState) => !prevState);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal((prev) => !prev);
  };

  const toggleCloseModal = () => {
    setIsShowCloseModal((prev) => !prev);
  };

  const toggleShowModal = () => {
    setIsShowModal((item) => !item);
  };

  const handleDelete = () => {
    if (ticket?.id) {
      dispatch(deleteTicketRequest(ticket));
    }
  };

  const handleShowModalDelete = () => {
    if (ticket?.id) {
      setShowDeleteModal(true);
    }
  };

  const toggleReopenStatusModalOpen = () => {
    setNextStatus(REOPEN_STATUS);
    setIsEditStatusModalOpen(true);
  };

  const toggleModal = () => setIsModal(!isModal);

  const isJiraButtonDisabled = useMemo(
    () =>
      ticketStatus === TicketStatus.NEW ||
      ticketStatus === TicketStatus.CLOSE ||
      !!jiraUrl ||
      !isSystemIntegratedWithJira ||
      isClient,
    [ticketStatus, jiraUrl, isSystemIntegratedWithJira, isClient]
  );

  const showDrop = useMemo(
    () =>
      (isMobileAll && !isClient && isAccessToAssigningTicketToSpecialist) ||
      isAccessToDeleteTicket ||
      !isJiraButtonDisabled ||
      isShowTicketClosedButton,
    [
      isMobileAll,
      isClient,
      isAccessToAssigningTicketToSpecialist,
      isAccessToDeleteTicket,
      isJiraButtonDisabled,
      isShowTicketClosedButton,
    ]
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isDirty, isValid },
  } = useForm<{ message: string }>({
    mode: 'onChange',
  });

  const messageTextAreaOptions = register('message', {
    required: true,
    maxLength: {
      value: 1024,
      message: 'Текст комментария не может быть длиннее 1024-ти символов.',
    },
  });

  const handleRequest = (message: string) => {
    if (nextStatus && ticket?.id) {
      dispatch(
        reopenStatus
          ? reopenTicketRequest({ ticketId: ticket?.id, reasonReopen: message })
          : changeStatus({
              message,
              nextStatus,
              ticketId: ticket?.id,
              type: getCommentType(nextStatus),
            })
      );
    }
  };

  const formSubmitHandler = handleSubmit(({ message }) => {
    handleRequest(message);
    toggleIsEditStatusModalOpen();
    reset();
  });

  const disabledStatusChange = !isDirty || !isValid;

  const onChangeNextStatusHandler = (status: Status | null) => {
    setNextStatus(status);
    toggleIsPopoverOpen();
    if (status?.isComment) {
      toggleIsEditStatusModalOpen();
      return;
    }
    if (status && ticket?.id) {
      dispatch(
        changeStatus({
          nextStatus: status,
          ticketId: ticket?.id,
          type: getCommentType(status),
        })
      );
    }
  };

  return {
    state: {
      ticket,
      isClient,
      ticketStatus,
      isTickedClosed,
      isPopoverOpen,
      isShowModal,
      isJiraButtonDisabled,
      ticketPriority,
      isMobileAll,
      loading,
      isAccessToEditPriority,
      isModal,
      showDeleteModal,
      isEditStatusModalOpen,
      showDrop,
      nextStatuses,
      nextStatus,
      errors,
      disabledStatusChange,
      messageTextAreaOptions,
      isAccessToChangeStatus,
      isShowCloseModal,
      isTicketDeleted,
      isReopenTicket,
    },
    methods: {
      toggleShowModal,
      toggleIsPopoverOpen,
      handleShowModalDelete,
      toggleModal,
      handleDelete,
      toggleDeleteModal,
      toggleIsEditStatusModalOpen,
      onChangeNextStatusHandler,
      formSubmitHandler,
      toggleCloseModal,
      toggleReopenStatusModalOpen,
    },
  };
};
