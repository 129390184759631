import { v4 as uuidv4 } from 'uuid';

import { ColorScheme } from 'components-new/types';
import { SelectOption } from 'components/Select/types';
import { REOPEN_STATUS_SETTINGS } from 'core/constants';
import { Priority, Status } from 'core/types';
import { Languages } from 'i18n/types';

import { CommentType, CreateNote, VisibilityType } from './types';

export const PRIORITY_TITLE_MAP = {
  [Priority.NONE]: 'Без приоритета',
  [Priority.LOW]: 'Низкий приоритет',
  [Priority.MIDDLE]: 'Средний приоритет',
  [Priority.HIGH]: 'Высокий приоритет',
  [Priority.VERY_HIGH]: 'Критический приоритет',
};

export const PRIORITY_TITLE_MAP_EN = {
  [Priority.NONE]: 'Without priority',
  [Priority.LOW]: 'Low priority',
  [Priority.MIDDLE]: 'Middle priority',
  [Priority.HIGH]: 'High priority',
  [Priority.VERY_HIGH]: 'Very high priority',
};

export const getPriorityByTitleTitleMap = (language: Languages) => {
  const isRU = language.includes(Languages.RU);
  return isRU ? PRIORITY_TITLE_MAP : PRIORITY_TITLE_MAP_EN;
};

export const RADIO_TABS_FOR_SPECIALIST = [
  {
    title: 'Отправить на запрос информации',
    value: CommentType.INFO_REQUEST,
  },
  {
    title: 'Отправить на подтверждение закрытия',
    value: CommentType.CLOSE_REQUEST,
  },
];

export const RADIO_TABS_FOR_USER = [
  {
    title: 'Вернуть в работу',
    value: CommentType.RETURN_TO_WORK,
  },
];

export const PRIORITY_TITLE_MAP_SHORT = {
  [Priority.NONE]: 'Без приоритета',
  [Priority.LOW]: 'Низкий',
  [Priority.MIDDLE]: 'Средний',
  [Priority.HIGH]: 'Высокий',
  [Priority.VERY_HIGH]: 'Критический',
};

const PRIORITY_TITLE_MAP_SHORT_EN = {
  [Priority.NONE]: 'Without priority',
  [Priority.LOW]: 'Low',
  [Priority.MIDDLE]: 'Middle',
  [Priority.HIGH]: 'High',
  [Priority.VERY_HIGH]: 'Very high',
};

export const getPriorityByTitleTitleShortMap = (language: Languages) => {
  const isRU = language.includes(Languages.RU);
  return isRU ? PRIORITY_TITLE_MAP_SHORT : PRIORITY_TITLE_MAP_SHORT_EN;
};

const PRIVATE = 'Видно только вам';
const WORKGROUPS = 'Видно всем';

export const VISIBILITY_OPTIONS: SelectOption<VisibilityType>[] = [
  {
    title: PRIVATE,
    value: VisibilityType.PRIVATE,
  },
  {
    title: WORKGROUPS,
    value: VisibilityType.WORKGROUPS,
  },
];

export const VISIBILITY_TYPE_MAP = {
  [VisibilityType.PRIVATE]: PRIVATE,
  [VisibilityType.WORKGROUPS]: WORKGROUPS,
};

export const COLOR_SCHEME_MAP: Record<VisibilityType, ColorScheme> = {
  [VisibilityType.PRIVATE]: 'grey',
  [VisibilityType.WORKGROUPS]: 'blue',
};

export const NOTE_DEFAULT_VALUES: CreateNote = {
  text: '',
  visibility: VISIBILITY_OPTIONS[1],
};

export const REOPEN_STATUS: Status = {
  ...REOPEN_STATUS_SETTINGS,
  id: uuidv4(),
  name: REOPEN_STATUS_SETTINGS.label,
};

export const TEXT_CONTENT_TICKET_TYPE_EDITOR: Record<string, string> = {
  editTicketType: 'Изменить тип тикета',
  createTitle: 'Изменить',
  cancelTitle: 'Отменить',
  subModalText: 'изменение типа тикета',
};

export const DEFAULT_TEXT_TICKET_TOAST: Record<string, string> = {
  success: 'Успешно изменено',
  error: 'Что-то пошло не так...',
};

export const TEXT_TICKET_TOAST: Record<string, string> = {
  changeTicketType: 'Тип тикета изменен',
  changeTicketPriority: 'Приоритет тикета изменен',
};

export const TITLE_FROM_PRIORITY_MAP: Record<string, Priority> = {
  'Без приоритета': Priority.NONE,
  Низкий: Priority.LOW,
  Средний: Priority.MIDDLE,
  Высокий: Priority.HIGH,
  Критический: Priority.VERY_HIGH,
};
