import { isEqual } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { TicketTypeSettings } from 'core/types';
import { SLATableDataType } from 'features/Contract/components/SlaTable/types';
import {
  createSLAPatternRequestContractDetail,
  fetchSystemsForAddingToContractRequestContractDetail,
  fetchTicketTypesRequestContractDetail,
  setSelectedSystemSlaContractDetail,
} from 'features/Contract/ducks/actions';
import { useContractId } from 'features/Contract/hooks/useContractId';
import { SLAChoiceType, SLAType } from 'features/Contract/types';
import { CreateSystemDataAdd } from 'features/Systems/types';

import { Props } from '../components/CreateSLA';
import {
  checkValidTableSLA,
  excludeUncheckedRows,
  prepareSLATableForRequest,
} from '../components/CreateSLA/utils';
import {
  getInitialCheckedSLA,
  getSlaTicketTypesFromSettings,
  initialCheckedPriority,
} from '../components/SlaTable';
import { getSelectedSystemSla } from '../ducks/selectors';
import { getActiveTicketTypes } from '../utils';

export const useCreateSLA = ({
  toggleModal,
  isCreateMode = true,
  canChooseSystem = true,
  sla,
  slaTicketTypes,
  onCreate,
  onChange,
  setIsValidSlaTicketTypes,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const dispatch = useDispatch();
  const contractId = useContractId();

  const selectedSystemSla = useSelector(getSelectedSystemSla);

  const titleModal = isCreateMode ? 'Добавить систему' : 'Изменить SLA';
  const createTitle = isCreateMode ? 'Добавить' : 'Сохранить';

  const [initialSlaSettings, setInitialSlaSettings] =
    useState<SLATableDataType>({} as SLATableDataType);

  const [localSlaTableData, setLocalSlaTableData] =
    useState<SLATableDataType>(initialSlaSettings);

  const [ticketTypes, setTicketTypes] = useState<TicketTypeSettings[]>();

  const [checkedPriority, setCheckedPriority] = useState(
    initialCheckedPriority
  );
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const togglePopover = () => {
    setIsPopoverOpen((prev) => !prev);
  };

  const { register, handleSubmit, setValue, watch, control } =
    useForm<CreateSystemDataAdd>({
      mode: 'onChange',
      defaultValues: {
        createType: SLAChoiceType.CREATE_NEW,
      },
    });

  const workingHoursInput = register('workingHoursFrom', {
    required: true,
  });
  const workingHoursToInput = register('workingHoursTo', {
    required: true,
  });

  const {
    workingHoursFrom,
    workingHoursTo,
    closeAfterHours,
    createType,
    autoClosableTickets,
    expireReactionTimeInDecisionTime,
    systemId: slaForSystem,
  } = watch();

  const isDataTableValid = useMemo(
    () => checkValidTableSLA(localSlaTableData, checkedPriority),
    [localSlaTableData, checkedPriority]
  );

  const isValidSLAExceptTable = useMemo(() => {
    const autoClosingValid = autoClosableTickets ? closeAfterHours : true;
    return Boolean(workingHoursFrom && workingHoursTo && autoClosingValid);
  }, [
    workingHoursFrom,
    workingHoursTo,
    autoClosableTickets,
    closeAfterHours,
    isCreateMode,
    slaForSystem,
  ]);

  const isValidSLASystem = Boolean(canChooseSystem ? slaForSystem : true);
  const isValidTicketTypes = !!ticketTypes?.length;
  const isDataSLAValid = isDataTableValid && isValidSLAExceptTable;
  const isUnequalData = !isEqual(sla?.settings, localSlaTableData);

  const disabledSubmit = !(
    isDataTableValid &&
    isValidSLAExceptTable &&
    isValidSLASystem &&
    isValidTicketTypes &&
    isUnequalData
  );

  const closeModal = () => {
    toggleModal?.();
  };

  const updateSlaSettings = (data: SLATableDataType) => {
    setLocalSlaTableData(data);
  };

  const resetSLAData = () => {
    setInitialSlaSettings({} as SLATableDataType);
    updateSlaSettings({} as SLATableDataType);
    setCheckedPriority({ ...initialCheckedPriority });
    setTicketTypes(undefined);
    setValue('workingHoursFrom', '');
    setValue('workingHoursTo', '');
    setValue('autoClosableTickets', false);
    setValue('closeAfterHours', undefined);
    setValue('expireReactionTimeInDecisionTime', false);
    setIsPopoverOpen(false);
  };

  const formSubmitHandler = handleSubmit((data) => {
    const { systemId: selectedSystemId, ...slaData } = data;
    delete slaData.createType;
    const isSystemValid = canChooseSystem ? selectedSystemId : true;

    if (isSystemValid && contractId && slaData && onCreate) {
      const settings = prepareSLATableForRequest(
        excludeUncheckedRows({
          settings: localSlaTableData,
          checkedPriority,
          ticketTypes,
        })
      );

      onCreate({
        contractId,
        systemId: selectedSystemId,
        sla: {
          id: sla?.id,
          ...slaData,
          settings,
        },
      });
    }
    closeModal();
    setTicketTypes(undefined);
    resetSLAData();
  });

  const onCreatePattern = (title: string) => {
    const settings = prepareSLATableForRequest(
      excludeUncheckedRows({ settings: localSlaTableData, checkedPriority })
    );
    dispatch(
      createSLAPatternRequestContractDetail({
        title,
        workingHoursFrom,
        workingHoursTo,
        closeAfterHours: closeAfterHours || 0,
        autoClosableTickets: autoClosableTickets || false,
        expireReactionTimeInDecisionTime:
          expireReactionTimeInDecisionTime || false,
        settings,
      })
    );
  };

  const handleActiveTicketType = (id: string) => {
    return (event: React.ChangeEvent<HTMLInputElement>) => {
      setTicketTypes((prev) => {
        const result = prev?.map((item) => {
          if (item.ticketTypeId === id) {
            return { ...item, active: event.target.checked };
          }
          return item;
        });

        const activeTicketTypes = getActiveTicketTypes(result);

        if (activeTicketTypes) {
          setLocalSlaTableData((prevLocalSlaTableData) =>
            excludeUncheckedRows({
              settings: prevLocalSlaTableData,
              checkedPriority,
              ticketTypes: result,
            })
          );
        }

        return result;
      });
    };
  };

  const setSelectedSystem = (value: string) => {
    setValue('systemId', value);

    dispatch(fetchTicketTypesRequestContractDetail(value));
    dispatch(setSelectedSystemSlaContractDetail(value));
  };

  const copySlaSettingsFromPattern = (data: SLAType) => {
    setValue('createType', SLAChoiceType.CREATE_NEW);
    setValue('workingHoursFrom', data.workingHoursFrom);
    setValue('workingHoursTo', data.workingHoursTo);
    setValue('autoClosableTickets', data.autoClosableTickets);
    setValue('closeAfterHours', data.closeAfterHours);
    setValue(
      'expireReactionTimeInDecisionTime',
      data.expireReactionTimeInDecisionTime
    );
    setInitialSlaSettings({ ...data.settings });
    updateSlaSettings(data.settings);
    setTicketTypes(getSlaTicketTypesFromSettings(data.settings));
  };

  useEffect(() => {
    setCheckedPriority(getInitialCheckedSLA(initialSlaSettings));
  }, [initialSlaSettings]);

  useEffect(() => {
    if (slaTicketTypes && !!selectedSystemSla) {
      setTicketTypes(slaTicketTypes);
    }
  }, [slaTicketTypes, selectedSystemSla]);

  useEffect(() => {
    if (onChange) {
      const settings = prepareSLATableForRequest(
        excludeUncheckedRows({
          settings: localSlaTableData,
          checkedPriority,
          ticketTypes,
        })
      );

      onChange({
        data: {
          workingHoursFrom,
          workingHoursTo,
          closeAfterHours,
          autoClosableTickets,
          expireReactionTimeInDecisionTime,
          settings,
        },
        isValid:
          checkValidTableSLA(settings, checkedPriority) &&
          isValidSLAExceptTable,
      });
    }
  }, [
    workingHoursFrom,
    workingHoursTo,
    closeAfterHours,
    autoClosableTickets,
    expireReactionTimeInDecisionTime,
    slaForSystem,
    localSlaTableData,
    isDataTableValid,
    isValidSLAExceptTable,
    checkedPriority,
  ]);

  useEffect(() => {
    if (canChooseSystem) {
      dispatch(fetchSystemsForAddingToContractRequestContractDetail());
    }
  }, []);

  useEffect(() => {
    if (createType === SLAChoiceType.SELECT_FROM_LIST) {
      resetSLAData();
    }
  }, [createType]);

  useEffect(() => {
    if (sla && slaTicketTypes) {
      setTicketTypes(slaTicketTypes);
    }

    if (sla?.settings) {
      setInitialSlaSettings(sla.settings);
      updateSlaSettings(sla.settings);
    }
    setValue('workingHoursFrom', sla?.workingHoursFrom || '');
    setValue('workingHoursTo', sla?.workingHoursTo || '');
    setValue('autoClosableTickets', sla?.autoClosableTickets);
    setValue('closeAfterHours', sla?.closeAfterHours);
    setValue(
      'expireReactionTimeInDecisionTime',
      sla?.expireReactionTimeInDecisionTime
    );
  }, [sla, slaTicketTypes]);

  useEffect(() => {
    if (setIsValidSlaTicketTypes) {
      setIsValidSlaTicketTypes(!!ticketTypes?.length);
    }
  }, [ticketTypes]);

  return {
    methods: {
      setValue,
      resetSLAData,
      onCreatePattern,
      setCheckedPriority,
      updateSlaSettings,
      setSelectedSystem,
      copySlaSettingsFromPattern,
      togglePopover,
      formSubmitHandler,
      handleActiveTicketType,
    },
    state: {
      control,
      closeAfterHours,
      autoClosableTickets,
      expireReactionTimeInDecisionTime,
      workingHoursInput,
      workingHoursToInput,
      isDataSLAValid,
      disabledSubmit,
      localSlaTableData,
      checkedPriority,
      ticketTypes,
      slaForSystem,
      isPopoverOpen,
      createTitle,
      titleModal,
      createType,
    },
  };
};
