import { call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects';

import { setAlert } from 'core/ducks/actions';
import { createSuccessAlert } from 'core/layouts/AlertsLayout';
import { WorkGroup } from 'core/types';
import { AccessedRoute, CurrentUserOrganization } from 'features/Auth/types';
import { ResponseWithMeta } from 'store/types';
import { AuthService, createError } from 'utils';

import {
  fetchPermissionsSuccess,
  setAuthorized,
  setUserOrganization,
} from './actions';
import { request } from './api/requests';
import { getUserId } from './selectors';
import { ChangeUserPasswordAction, CurrentUserTypes } from './types';

function* accessedRoutesFetch() {
  try {
    const accessedRoutes: AccessedRoute[] = yield call(
      request.fetchAccessedRoutes
    );
    const actionList: string[] = yield call(request.fetchActionList);
    const userId: ReturnType<typeof getUserId> = yield select(getUserId);
    const usersWorkGroups: ResponseWithMeta<WorkGroup[]> = yield call(
      request.fetchUsersWorkGroups,
      userId || ''
    );
    const managerWorkGroupsIds: string[] = yield call(
      request.fetchManagerWorkGroups,
      userId || ''
    );
    const userOrganization: CurrentUserOrganization = yield call(
      request.fetchUserOrganization
    );
    yield put(setUserOrganization(userOrganization));
    yield put(
      fetchPermissionsSuccess({
        accessedRoutes,
        actionList,
        workGroups: usersWorkGroups.content,
        managerWorkGroupsIds,
      })
    );
    yield put(setAuthorized(true));
  } catch (e) {
    createError(e);
    AuthService.kc.logout();
  }
}

function* changePassword({ payload }: ChangeUserPasswordAction) {
  try {
    yield call(request.changePassword, payload);
    yield put(setAlert(createSuccessAlert('Ваш пароль успешно изменен')));
  } catch (e) {
    createError(e);
  }
}

export function* currentUserSaga(): Generator<StrictEffect> {
  yield takeEvery(
    CurrentUserTypes.FETCH_USER_PERMISSIONS_REQUEST,
    accessedRoutesFetch
  );
  yield takeEvery(CurrentUserTypes.CHANGE_PASSWORD, changePassword);
}
