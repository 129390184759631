import { WorkGroup } from 'core/types';
import {
  AccessedRoute,
  CurrentUser,
  CurrentUserOrganization,
} from 'features/Auth/types';

export enum CurrentUserTypes {
  SET_CURRENT_USER = 'AUTH/SET_CURRENT_USER',
  SET_AUTHORIZED = 'AUTH/SET_AUTHORIZED',
  FETCH_USER_PERMISSIONS_REQUEST = 'AUTH/FETCH_USER_PERMISSIONS_REQUEST',
  FETCH_USER_PERMISSIONS_SUCCESS = 'AUTH/FETCH_USER_PERMISSIONS_SUCCESS',
  CHANGE_PASSWORD = 'AUTH/CHANGE_PASSWORD',
  SET_USER_ORGANIZATION = 'AUTH/SET_USER_ORGANIZATION',
}

export interface SetCurrentUserAction {
  type: CurrentUserTypes.SET_CURRENT_USER;
  payload: CurrentUser;
}

export interface SetAuthorizedAction {
  type: CurrentUserTypes.SET_AUTHORIZED;
  payload: boolean;
}

export interface FetchPermissionsRequestAction {
  type: CurrentUserTypes.FETCH_USER_PERMISSIONS_REQUEST;
}

export interface FetchPermissionsSuccessActionPayload {
  accessedRoutes: AccessedRoute[];
  actionList: string[];
  workGroups: WorkGroup[];
  managerWorkGroupsIds: string[];
}

export interface FetchPermissionsSuccessAction {
  type: CurrentUserTypes.FETCH_USER_PERMISSIONS_SUCCESS;
  payload: FetchPermissionsSuccessActionPayload;
}

export interface ChangeUserPasswordAction {
  type: CurrentUserTypes.CHANGE_PASSWORD;
  payload: string;
}

export interface SetUserOrganizationAction {
  type: CurrentUserTypes.SET_USER_ORGANIZATION;
  payload: CurrentUserOrganization;
}

export type CurrentUserActionsTypes =
  | SetCurrentUserAction
  | FetchPermissionsSuccessAction
  | SetAuthorizedAction
  | SetUserOrganizationAction;
