import cn from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  ArrowInBoxIcon,
  ChangeIcon,
  CheckMarkIcon,
  Clock12,
  ClockFat,
  CloseIcon,
  DeleteIcon,
  ExclamationIcon,
  HashtagIcon,
  LabelIcon,
  LightBulbIcon,
  LoadingIcon,
  PenIcon,
  ReloadIcon,
  SmallBell,
  StorageFileIcon,
  StorageIcon,
  StoragePenIcon,
  UserIcon,
} from 'assets/icons';
import { IconButtonWrapper } from 'components/IconButtonWrapper';
import { NotificationCircle } from 'components/NotificationCircle';
import { Typography, TypographyVariants } from 'components/Typography';
import {
  getNotificationTitle,
  getTitleNotification,
} from 'features/Notifications/utils';
import { RouterHref } from 'routes/routerHref';
import { getFormatDate } from 'utils';

import {
  deleteAllCurrentNotifications,
  deleteCurrentNotification,
  deleteNotificationById,
} from '../../ducks/actions';
import { EntityTypes, NotificationContent } from '../../types';

import styles from './Notification.module.scss';
import { NotificationType as NotificationTypeEnum } from './types';

interface NotificationInfoData {
  notificationType?: string;
}

const getNotificationInfo = ({
  notificationType,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
NotificationInfoData) => {
  if (
    notificationType === NotificationTypeEnum.SPECIALIST_TOOK_TICKET_TO_WORK
  ) {
    return {
      classNameIcon: styles.notification__icon_blue,
      icon: <LoadingIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.ASSIGNED_ANOTHER_SPECIALIST) {
    return {
      classNameIcon: styles.notification__icon_purple,
      icon: <UserIcon className={styles.notification__icon_small} />,
    };
  }
  if (
    notificationType ===
    NotificationTypeEnum.SPECIALIST_SENT_INFORMATION_REQUEST
  ) {
    return {
      classNameIcon: styles.notification__icon_purple,
      icon: <PenIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.SPECIALIST_SUGGESTED_SOLUTION) {
    return {
      classNameIcon: styles.notification__icon_green,
      icon: <LightBulbIcon />,
    };
  }
  if (
    notificationType === NotificationTypeEnum.YOU_HAVE_BEEN_ASSIGNED_TICKET ||
    notificationType ===
      NotificationTypeEnum.YOU_HAVE_BEEN_ASSIGNED_TICKET_BY_SYSTEM
  ) {
    return {
      classNameIcon: styles.notification__icon_cyan,
      icon: <UserIcon className={styles.notification__icon_small} />,
    };
  }
  if (
    notificationType ===
    NotificationTypeEnum.TICKET_WAS_ASSIGNED_ANOTHER_SPECIALIST
  ) {
    return {
      classNameIcon: styles.notification__icon_cyan,
      icon: <UserIcon className={styles.notification__icon_small} />,
    };
  }
  if (notificationType === NotificationTypeEnum.CLIENT_PROVIDED_INFORMATION) {
    return {
      classNameIcon: styles.notification__icon_purple,
      icon: <PenIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.CLIENT_REJECTED_SOLUTION) {
    return {
      classNameIcon: styles.notification__icon_red,
      icon: <CloseIcon className={styles.notification__icon_tiny} />,
    };
  }
  if (notificationType === NotificationTypeEnum.CLIENT_ACCEPTED_SOLUTION) {
    return {
      classNameIcon: styles.notification__icon_green,
      icon: <CheckMarkIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.SLA_DECISION_EXPIRING) {
    return {
      classNameIcon: styles.notification__icon_orange,
      icon: <Clock12 />,
    };
  }
  if (notificationType === NotificationTypeEnum.SLA_DECISION_EXPIRED) {
    return {
      classNameIcon: styles.notification__icon_deepRed,
      icon: <Clock12 />,
    };
  }
  if (notificationType === NotificationTypeEnum.SLA_REACTION_EXPIRING) {
    return {
      classNameIcon: styles.notification__icon_orange,
      icon: <Clock12 />,
    };
  }
  if (notificationType === NotificationTypeEnum.SLA_REACTION_EXPIRED) {
    return {
      classNameIcon: styles.notification__icon_deepRed,
      icon: <Clock12 />,
    };
  }
  if (
    notificationType === NotificationTypeEnum.SLA_DECISION_EXPIRED_FOR_MANAGER
  ) {
    return {
      classNameIcon: styles.notification__icon_deepRed,
      icon: <Clock12 />,
    };
  }
  if (
    notificationType === NotificationTypeEnum.TICKET_AUTO_CLOSE_NOTIFICATION
  ) {
    return {
      classNameIcon: styles.notification__icon_green,
      icon: <LightBulbIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.CLIENT_CREATED_NEW_TICKET) {
    return {
      classNameIcon: styles.notification__icon_orange,
      icon: <LabelIcon />,
    };
  }
  if (
    notificationType === NotificationTypeEnum.STATUS_CHANGE_FOR_SPECIALIST ||
    notificationType === NotificationTypeEnum.STATUS_CHANGE_FOR_CLIENT
  ) {
    return {
      classNameIcon: styles.notification__icon_lightestBlue,
      icon: <ArrowInBoxIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.SYSTEM_INDEX_CHANGE) {
    return {
      classNameIcon: styles.notification__icon_lightestBlue,
      icon: <HashtagIcon className={styles.notification__icon_small} />,
    };
  }
  if (notificationType === NotificationTypeEnum.TICKET_WAS_CLOSED_BY_ADMIN) {
    return {
      classNameIcon: styles.notification__icon_deepRed,
      icon: <ExclamationIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.NEW_COMMENT) {
    return {
      classNameIcon: styles.notification__icon_purple,
      icon: <PenIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.FILE_UPDATED_IN_STORAGE) {
    return {
      classNameIcon: styles.notification__icon_purple,
      icon: <StoragePenIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.NEW_FILE_UPLOAD_IN_STORAGE) {
    return {
      classNameIcon: styles.notification__icon_lightBlue,
      icon: <StorageFileIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.STORAGE_ACCESS_GRANTED) {
    return {
      classNameIcon: styles.notification__icon_green,
      icon: <StorageIcon className={styles.notification__icon_small} />,
    };
  }
  if (notificationType === NotificationTypeEnum.TICKET_WAS_DELETED) {
    return {
      classNameIcon: styles.notification__icon_deepRed,
      icon: <DeleteIcon className={styles.notification__icon_small} />,
    };
  }
  if (
    notificationType === NotificationTypeEnum.TICKET_PRIORITY_CHANGED ||
    notificationType === NotificationTypeEnum.TICKET_WAS_REOPENED
  ) {
    return {
      classNameIcon: styles.notification__icon_orange,
      icon: <ChangeIcon className={styles.notification__icon_small} />,
    };
  }
  if (notificationType === NotificationTypeEnum.TICKET_WAS_RESTORED) {
    return {
      classNameIcon: styles.notification__icon_green,
      icon: <ReloadIcon />,
    };
  }
  if (notificationType === NotificationTypeEnum.TICKET_TYPE_CHANGED) {
    return {
      classNameIcon: styles.notification__icon_purple,
      icon: <ChangeIcon className={styles.notification__icon_small} />,
    };
  }
  return null;
};

type NotificationType = 'default' | 'toast';

export interface Props {
  className?: string;
  type?: NotificationType;
  notification?: NotificationContent;
  notificationCount?: number;
  entities?: string[];
  entitiesType?: EntityTypes;
  onClick?: (value: NotificationContent) => void;
  classNameTitle?: string;
  classNameText?: string;
  disabled?: boolean;
}

export const Notification: React.FC<Props> = ({
  className,
  type = 'default',
  notification,
  notificationCount,
  entities,
  entitiesType = EntityTypes.TICKET,
  onClick,
  classNameTitle,
  classNameText,
  disabled,
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const { t } = useTranslation('notifications');

  const dispatch = useDispatch();

  const isToast = type === 'toast';
  const isEntityStorage = notification?.entityType === EntityTypes.STORAGE;
  const linkTo = isEntityStorage ? RouterHref.Storages : RouterHref.Tickets;

  const notificationInfo = getNotificationInfo({
    notificationType: notification?.notificationType,
  });

  const readNotificationHandler = () => {
    if (notification?.entityId) {
      return dispatch(deleteCurrentNotification(notification?.entityId));
    }
    return dispatch(deleteAllCurrentNotifications());
  };

  const closeToastHandler = () => {
    if (notification?.id) {
      return dispatch(deleteNotificationById(notification?.id));
    }
    return dispatch(deleteAllCurrentNotifications());
  };

  const entitiesLinks = !entities ? (
    <Link
      to={`${linkTo}/${notification?.entityId}`}
      onClick={readNotificationHandler}
      className={styles.notification__ticketLink}
    >
      {isEntityStorage
        ? `#${notification?.linkName}`
        : `${t('notification.ticketLinkTitle')} #${notification?.linkName}`}
    </Link>
  ) : (
    entities.slice(-3).map((entity) => {
      const parsedEntity = JSON.parse(entity);
      return entitiesType === EntityTypes.TICKET ? (
        <Link
          key={entity}
          to={`${RouterHref.Tickets}/${parsedEntity?.id}`}
          onClick={readNotificationHandler}
          className={cn(styles.notification__ticketLink, {
            [styles.notification__ticketLink_list]:
              entities.slice(-3).length > 1,
          })}
        >
          {`${t('notification.ticketLinkTitle')} #${parsedEntity?.number}`}
        </Link>
      ) : (
        <Typography
          variant={TypographyVariants.b3}
          className={styles.notification__ticketLink_fake}
        >
          {parsedEntity.number}
        </Typography>
      );
    })
  );

  const systemTitle = (
    <Typography
      variant={TypographyVariants.b3}
      className={styles.notification__ticketLink_fake}
    >
      {notification?.linkName}
    </Typography>
  );

  const title = notificationCount
    ? `У вас ${notificationCount} ${getNotificationTitle(notificationCount)}`
    : notification?.notificationTitle;

  const notificationText =
    Boolean(entities && notificationCount) &&
    `${notificationCount} из ${entities?.length} ${getTitleNotification(
      entitiesType,
      entities?.length
    )}`;

  const closeIcon = !isToast ? (
    <NotificationCircle
      className={cn({
        [styles.notification__circle_hide]: notification?.checked,
      })}
    />
  ) : (
    <IconButtonWrapper
      icon={<CloseIcon className={styles.notification__cross} />}
      onClick={closeToastHandler}
      className={styles.notification__closeToast}
    />
  );

  const date = !isToast && (
    <div className={styles.notification__timeWrapper}>
      <ClockFat className={styles.notification__timeIcon} />
      <span className={styles.notification__time}>
        {getFormatDate(notification?.createdWhen || '')}
      </span>
    </div>
  );

  const icon = notificationCount ? <SmallBell /> : notificationInfo?.icon;

  const handleNotificationClick = () => {
    if (onClick && notification) {
      onClick(notification);
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  if (!notificationInfo && !notificationCount) {
    return null;
  }

  return (
    <div
      className={cn(styles.notification, className)}
      onClick={!disabled ? handleNotificationClick : undefined}
      tabIndex={onClick && !disabled ? 0 : -1}
      onKeyDown={handleKeyDown}
      role="button"
    >
      <div
        className={cn(
          styles.notification__icon,
          notificationInfo?.classNameIcon,
          {
            [styles.notification__icon_purple]: entities,
          }
        )}
      >
        {icon}
      </div>
      <div className={styles.notification__infoBlock}>
        <div className={styles.notification__header}>
          <div className={styles.notification__headerText}>
            <Typography
              variant={TypographyVariants.h5}
              component="div"
              className={cn(styles.notification__title, classNameTitle)}
            >
              {title}
            </Typography>
            <p className={cn(styles.notification__text, classNameText)}>
              {!entities && notification?.notificationMessage}
              {notificationText}
            </p>
          </div>
          {closeIcon}
        </div>
        <div
          className={cn(styles.notification__footer, {
            [styles.notification__footer_multi]: notificationCount,
          })}
        >
          {notification?.entityType === EntityTypes.SYSTEM && !entities
            ? systemTitle
            : entitiesLinks}
          {date}
        </div>
      </div>
    </div>
  );
};
