import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Edge, Node } from 'reactflow';
import { v4 as uuidv4 } from 'uuid';

import { TicketStatus } from 'core/types';
import { EdgeType, NodeType } from 'core/types/workflow';

import { Props as StatusDetailProps } from '../components/StatusDetail';
import { DEFAULT_STATUS_FORM_VALUES } from '../constants';
import { StatusForm } from '../types';
import {
  getDataReopenStatus,
  getDataWaitingInfoStatus,
  getNormalizedData,
} from '../utils';

interface Props extends Omit<StatusDetailProps, 'onDeleteNodeHandler'> {}

type CreateStatusChainArgs = {
  node: Node<NodeType>;
  edgeTo: Edge<EdgeType>;
  edgeFrom: Edge<EdgeType>;
};

export const useStatusForm = ({
  onClose,
  onCreateNodeHandler,
  onCreateEdgeHandler,
  nodes,
  selectedNode,
  onResetSelectedNode,
  onEditNodeHandler,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
Props) => {
  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { isDirty, isValid, errors },
    reset,
    setValue,
  } = useForm<StatusForm>({
    mode: 'onChange',
    defaultValues: DEFAULT_STATUS_FORM_VALUES,
  });
  const [isModal, setIsModal] = useState(false);

  const toggleModal = () => {
    setIsModal((prevState) => !prevState);
  };

  const { label, isComment } = watch();

  const currentFormValues = watch();

  const labelInputOptions = register('label', {
    required: true,
    maxLength: {
      value: 24,
      message: 'Название статуса не может быть длиннее 24 символов.',
    },
  });

  const colorRadioOptions = register('color', { required: true });

  const nodeStatusWork = useMemo(
    () => nodes.find((node) => node.data.label === 'В работе'),
    [nodes]
  );
  const nodeStatusClose = nodes.find((node) => node.data.label === 'Закрыт');
  const nodeStatusPendingClosure = nodes.find(
    (node) => node.data.label === 'В ожидании закрытия'
  );

  const onCloseModalHandler = useCallback(() => {
    if (selectedNode) {
      onResetSelectedNode();
    }
    onClose();
  }, [selectedNode, onResetSelectedNode, onClose]);

  const formSubmitHandler = handleSubmit((data) => {
    if (!selectedNode) {
      onCreateNodeHandler({
        id: uuidv4(),
        data: {
          ...data,
          deletable: true,
          modified: true,
        },
        position: { x: 100, y: 100 },
      });
    } else {
      onEditNodeHandler(selectedNode?.id, {
        ...data,
      });
    }
    onCloseModalHandler();
    reset();
  });

  const isIdenticalLabel = useMemo(() => {
    if (label?.length) {
      if (!selectedNode) {
        return !!nodes.find((node) => node.data.label === label);
      }
      return !!nodes
        .filter((node) => node.id !== selectedNode.id)
        .find((node) => node.data.label === label);
    }
    return false;
  }, [label, nodes, selectedNode]);

  const isWaitingInfoLabel =
    label === 'В ожидании информации' &&
    selectedNode?.data.defaultStatus !== TicketStatus.WAITING_INFO;

  const isWaitingInfoStatus = useMemo(() => {
    return !!nodes.find((node) => node.data.label === 'В ожидании информации');
  }, [nodes]);

  const isReopenStatus = !!nodes.find(
    (node) => node.data.label === 'Переоткрыт'
  );

  const handleCreateStatusChain = ({
    node,
    edgeFrom,
    edgeTo,
  }: CreateStatusChainArgs) => {
    onCreateNodeHandler(node);
    onCreateEdgeHandler(edgeFrom);
    onCreateEdgeHandler(edgeTo);
  };

  const onCreateWaitingInfoStatus = useCallback(() => {
    if (nodeStatusWork) {
      const data = getDataWaitingInfoStatus(nodeStatusWork);
      handleCreateStatusChain(data);
    }
    onClose();
  }, [onClose, nodeStatusWork]);

  const onCreateReopenStatus = useCallback(() => {
    if (nodeStatusClose && nodeStatusWork && nodeStatusPendingClosure) {
      const data = getDataReopenStatus({
        nodeStatusClose,
        nodeStatusWork,
        nodeStatusPendingClosure,
      });
      handleCreateStatusChain(data);
    }
    onClose();
  }, [onClose, nodeStatusClose, nodeStatusWork, nodeStatusPendingClosure]);

  const isEditDisabled = useMemo(
    () => selectedNode && !selectedNode.data.modified,
    [selectedNode]
  );

  const isDeleteDisabled = useMemo(
    () => !selectedNode || !selectedNode.data.deletable,
    [selectedNode]
  );

  const modalTitle = useMemo(
    () => (selectedNode ? 'Просмотр статуса' : 'Создать статус'),
    [selectedNode]
  );

  const isIdenticalData = useMemo(() => {
    return (
      _.isEqual(currentFormValues, getNormalizedData(selectedNode?.data)) &&
      !!selectedNode
    );
  }, [_, currentFormValues, selectedNode, getNormalizedData]);

  const isSubmitDisabled = useMemo(
    () =>
      !isDirty ||
      !isValid ||
      isIdenticalLabel ||
      isIdenticalData ||
      isWaitingInfoLabel,
    [isDirty, isValid, isIdenticalLabel, isIdenticalData, isWaitingInfoLabel]
  );

  useEffect(() => {
    if (selectedNode) {
      setValue('label', selectedNode.data.label);
      setValue('accessibility', selectedNode.data.accessibility);
      setValue('color', selectedNode.data.color);
      setValue('isNotifications', selectedNode.data.isNotifications);
      setValue('isSLA', selectedNode.data.isSLA);
      setValue('isComment', selectedNode.data.isComment);
      setValue('deletable', selectedNode.data.deletable);
      setValue('modified', selectedNode.data.modified);
      setValue('jumpCondition', selectedNode.data.jumpCondition);
      setValue('commentTypes', selectedNode.data.commentTypes);
      return;
    }
    reset();
  }, [selectedNode]);

  return {
    methods: {
      control,
      formSubmitHandler,
      onCreateWaitingInfoStatus,
      onCreateReopenStatus,
      onCloseModalHandler,
      toggleModal,
    },
    state: {
      errors,
      labelInputOptions,
      colorRadioOptions,
      isIdenticalLabel,
      isWaitingInfoStatus,
      isReopenStatus,
      isEditDisabled,
      isDeleteDisabled,
      modalTitle,
      isWaitingInfoLabel,
      isSubmitDisabled,
      isComment,
      isModal,
    },
  };
};
