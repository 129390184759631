import { SelectOption } from 'components/Select/types';
import { HeaderType } from 'core/layouts/MainLayout';

import { FieldsType, SupportType, TicketStatus } from './types';
import { Accessibility, NodeType } from './types/workflow';

export const HEADER_TYPE: HeaderType = 'withExtraInfo';

export const GREY_100 = 'var(--grey100)';

export const NO_DATA = 'Нет данных';

export const MAX_FILE_SIZE = 50000000;

export const MAX_FILE_SIZE_TEXT = 'Размер файла не должен превышать 50 MB';

export const IS_ACTIVE_OPTIONS: SelectOption<boolean>[] = [
  { title: 'Активно', value: true },
  { title: 'Неактивно', value: false },
];

const ORGANIZATIONS = 'Организация';
const SYSTEMS = 'Система';
const TICKET_TYPES = 'Тип тикета';
const TICKET_PRIORITIES = 'Приоритет';
const ENVIRONMENTS = 'Среда';
const CLIENTS = 'Клиент';

export const SELECT_ATTRIBUTES_LIST: SelectOption[] = [
  { title: ORGANIZATIONS, value: 'organizations' },
  { title: SYSTEMS, value: 'systems' },
  { title: TICKET_TYPES, value: 'ticketTypes' },
  { title: TICKET_PRIORITIES, value: 'ticketPriorities' },
  { title: ENVIRONMENTS, value: 'environments' },
  { title: CLIENTS, value: 'clients' },
];

export const SELECT_ATTRIBUTES_MAP = {
  [FieldsType.organizations]: ORGANIZATIONS,
  [FieldsType.systems]: SYSTEMS,
  [FieldsType.ticketTypes]: TICKET_TYPES,
  [FieldsType.ticketPriorities]: TICKET_PRIORITIES,
  [FieldsType.environments]: ENVIRONMENTS,
  [FieldsType.clients]: CLIENTS,
};

export const SUPPORT_RADIO_TABS = [
  {
    title: 'Расширенная',
    value: SupportType.EXTENDED,
    description: `При переходе в статус «Запрос информации» таймер SLA ставится на 
      паузу и возобновляется, когда клиент возвращает тикет в статус «В работе».`,
  },
  {
    title: 'Лицензионная',
    value: SupportType.LICENSED,
    description: `При переходе в статус «Запрос информации» таймер SLA сбрасывается.`,
  },
];

export const entityNames = {
  MY_TICKET: 'MY_TICKET',
  ALL_TICKET: 'ALL_TICKET',
  GROUPS_TICKETS: 'GROUPS_TICKETS',
  CONTRACTS_FULL: 'CONTRACTS_FULL',
  CONTRACTS_FROM_ORGANIZATIONS: 'CONTRACTS_FROM_ORGANIZATIONS',
  CONTRACTS_FROM_SYSTEMS: 'CONTRACTS_FROM_SYSTEMS',
  GROUPS_FULL: 'GROUPS_FULL',
  GROUPS_FROM_ORGANIZATIONS: 'GROUPS_FROM_ORGANIZATIONS',
  GROUPS_ADD_GROUPS: 'GROUPS_ADD_GROUPS',
  GROUPS_FROM_USERS: 'GROUPS_FROM_USERS',
  ORGANIZATIONS: 'ORGANIZATIONS',
  SYSTEMS_FULL: 'SYSTEMS_FULL',
  SYSTEMS_FROM_CONTRACTS: 'SYSTEMS_FROM_CONTRACTS',
  STATUSES_BUILDER_SYSTEMS: 'STATUSES_BUILDER_SYSTEMS',
  ROLES_FULL: 'ROLES_FULL',
  ROLES_FROM_USERS: 'ROLES_FROM_USERS',
  ROLES_FROM_GROUPS: 'ROLES_FROM_GROUPS',
  ROLES_ADD_MODAL: 'ROLES_ADD_MODAL',
  RULES: 'RULES',
  ACCESSES: 'ACCESSES',
  ACTIONS: 'ACTIONS',
  CUSTOM_FIELDS: 'CUSTOM_FIELDS',
  JIRA_INTEGRATION: 'JIRA_INTEGRATION',
  KB_TABLE_ARTICLES: 'KB_TABLE_ARTICLES',
  RESPONSIBILITIES_FULL: 'RESPONSIBILITIES_FULL',
  RESPONSIBILITIES_FROM_GROUPS: 'RESPONSIBILITIES_FROM_GROUPS',
  RESPONSIBILITIES_FROM_ROLES: 'RESPONSIBILITIES_FROM_ROLES',
  RESPONSIBILITIES_ADD_MODAL: 'RESPONSIBILITIES_ADD_MODAL',
  STORAGE: 'STORAGE',
  TRIGGERS: 'TRIGGERS',
  USERS_FULL: 'USERS_FULL',
  USERS_FROM_GROUPS: 'USERS_FROM_GROUPS',
  USERS_ADD_MODAL: 'USERS_ADD_MODAL',
  USERS_FROM_DESKTOP: 'USERS_FROM_DESKTOP',
  ENVIRONMENT: 'ENVIRONMENT',
};

export const DEFAULT_CUSTOM_FIELD_VALUES = {
  text: null,
  flag: null,
  date: null,
};

export const REOPEN_STATUS_SETTINGS: NodeType = {
  label: 'Переоткрыт',
  color: 'orange90',
  accessibility: Accessibility.ALL,
  isNotifications: true,
  isSLA: false,
  isComment: true,
  defaultStatus: TicketStatus.REOPEN,
  deletable: true,
  modified: false,
};

export const TICKET_STATUS_TITLE_MAP = {
  [TicketStatus.NEW]: 'Новый',
  [TicketStatus.WORK]: 'В работе',
  [TicketStatus.CLOSE]: 'Закрыт',
  [TicketStatus.PENDING_CLOSURE]: 'В ожидании закрытия',
  [TicketStatus.WAITING_INFO]: 'В ожидании информации',
  [TicketStatus.REOPEN]: 'Переоткрыт',
};

export const TICKET_STATUS_TITLE_MAP_EN = {
  [TicketStatus.NEW]: 'New',
  [TicketStatus.WORK]: 'In work',
  [TicketStatus.CLOSE]: 'Closed',
  [TicketStatus.PENDING_CLOSURE]: 'Pending close',
  [TicketStatus.WAITING_INFO]: 'Pending info',
  [TicketStatus.REOPEN]: 'Reopen',
};
