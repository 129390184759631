import { Organization, System, TicketType } from 'core/types';
import { Workflow, WorkflowParams } from 'core/types/workflow';
import { ResponseWithMeta } from 'store/types';

import { SystemsFilter } from '../types';

import {
  FetchOrganizationsRequestAction,
  FetchOrganizationsSuccessAction,
  FetchSystemsRequestAction,
  FetchSystemsSuccessAction,
  FetchTicketTypesForSystemRequestAction,
  FetchTicketTypesForSystemSuccessAction,
  FetchWorkflowRequestAction,
  FetchWorkflowSuccessAction,
  HideSystemsLoadingAction,
  ResetStatusesBuilderStateAction,
  SaveWorkflowAction,
  SetCurrentSystemIdAction,
  SetCurrentSystemsPageAction,
  SetPageSizeAction,
  SetSortSystemsAction,
  SetSystemsFilterAction,
  ShowSystemsLoadingAction,
  StatusesBuilder,
} from './types';

export const showSystemsLoading = (): ShowSystemsLoadingAction => {
  return {
    type: StatusesBuilder.BUILDER_SYSTEMS_LOADING_SHOW,
  };
};

export const hideSystemsLoading = (): HideSystemsLoadingAction => {
  return {
    type: StatusesBuilder.BUILDER_SYSTEMS_LOADING_HIDE,
  };
};

export const fetchSystemsRequest = (): FetchSystemsRequestAction => {
  return {
    type: StatusesBuilder.FETCH_BUILDER_SYSTEMS_REQUEST,
  };
};

export const fetchSystemsSuccess = (
  payload: ResponseWithMeta<System[]>
): FetchSystemsSuccessAction => {
  return {
    type: StatusesBuilder.FETCH_BUILDER_SYSTEMS_SUCCESS,
    payload,
  };
};

export const setCurrentSystemsPage = (
  page: number
): SetCurrentSystemsPageAction => {
  return {
    type: StatusesBuilder.SET_CURRENT_BUILDER_SYSTEMS_PAGE,
    payload: page,
  };
};

export const setPageSize = (size: number): SetPageSizeAction => {
  return {
    type: StatusesBuilder.SET_PAGE_SIZE_BUILDER_SYSTEMS,
    payload: size,
  };
};

export const setSortSystems = (sort: string): SetSortSystemsAction => {
  return {
    type: StatusesBuilder.SET_SORT_BUILDER_SYSTEMS,
    payload: sort,
  };
};

export const resetStatusesBuilderState =
  (): ResetStatusesBuilderStateAction => {
    return {
      type: StatusesBuilder.RESET_STATUSES_BUILDER_STATE,
    };
  };

export const setCurrentSystemId = (id?: string): SetCurrentSystemIdAction => {
  return {
    type: StatusesBuilder.SET_CURRENT_BUILDER_SYSTEM_ID,
    payload: id,
  };
};

export const setSystemsFilter = (
  data: SystemsFilter
): SetSystemsFilterAction => {
  return {
    type: StatusesBuilder.SET_BUILDER_SYSTEMS_FILTER,
    payload: data,
  };
};

export const fetchOrganizationsRequest =
  (): FetchOrganizationsRequestAction => {
    return {
      type: StatusesBuilder.FETCH_BUILDER_ORGANIZATIONS_REQUEST,
    };
  };

export const fetchOrganizationsSuccess = (
  payload: Organization[]
): FetchOrganizationsSuccessAction => {
  return {
    type: StatusesBuilder.FETCH_BUILDER_ORGANIZATIONS_SUCCESS,
    payload,
  };
};

export const fetchWorkflowRequest = (
  payload: WorkflowParams
): FetchWorkflowRequestAction => {
  return {
    type: StatusesBuilder.FETCH_WORKFLOW_REQUEST,
    payload,
  };
};

export const fetchWorkflowSuccess = (
  payload?: Workflow
): FetchWorkflowSuccessAction => {
  return {
    type: StatusesBuilder.FETCH_WORKFLOW_SUCCESS,
    payload,
  };
};

export const fetchTicketTypesForSystemRequest = (
  payload: string
): FetchTicketTypesForSystemRequestAction => {
  return {
    type: StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEM_REQUEST,
    payload,
  };
};

export const fetchTicketTypesForSystemSuccess = (
  payload?: TicketType[]
): FetchTicketTypesForSystemSuccessAction => {
  return {
    type: StatusesBuilder.FETCH_TICKET_TYPES_FOR_SYSTEM_SUCCESS,
    payload,
  };
};

export const saveWorkflow = (payload: Workflow): SaveWorkflowAction => {
  return {
    type: StatusesBuilder.SAVE_WORKFLOW,
    payload,
  };
};
