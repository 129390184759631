import { capitalize } from 'lodash';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CloseIcon,
  DownloadIcon,
  ZoomInIcon,
  ZoomOutIcon,
} from 'assets/icons';
import { Avatar } from 'components/Avatar';
import { Button } from 'components/Button';
import { IconButton } from 'components/IconButton';
import { ModalPortal } from 'components/ModalPortal';
import { Size } from 'components/types';
import { Typography, TypographyVariants } from 'components/Typography';
import { getTicketAttachments } from 'features/Ticket/ducks/attachments/selectors';
import { TicketFileType } from 'features/Ticket/types';
import { AttachmentsFiles } from 'types/models/attachment';
import { downloadFileWithToken } from 'utils';

import {
  CUSTOM_CONTENT_STYLE,
  CUSTOM_WRAPPER_STYLE,
  STEP_ZOOM,
} from '../TicketInfo/constants';
import { useCurrentAttachment } from '../TicketInfo/hooks/useCurrentAttachment';
import { getDataCreateAttachment } from '../TicketInfo/utils/getDataCreateAttachment';

import styles from './PreviewImageModal.module.scss';

interface Props {
  toggleModal(): void;
  initialImageIndex: number;
  imageFiles: AttachmentsFiles[];
}

export const PreviewImageModal: React.FC<Props> = ({
  toggleModal,
  initialImageIndex,
  imageFiles,
}) => {
  const ticketAttachments = useSelector(getTicketAttachments);
  const [currentImageIndex, setCurrentImageIndex] = useState(initialImageIndex);
  const modalRef = useRef<HTMLDivElement | null>(null);
  const currentImage = imageFiles[currentImageIndex];
  const { attachment } = useCurrentAttachment(ticketAttachments);
  const isSingleImage = imageFiles.length === 1;

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? imageFiles.length - 1 : prevIndex - 1
    );
  };

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === imageFiles.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleMouseDown = (
    e: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => {
    if (modalRef.current && !modalRef.current.contains(e.target as Node)) {
      toggleModal();
    }
  };

  const handleDownloadFile = (data: TicketFileType) => {
    const { e, path, fileName } = data;
    e.stopPropagation();
    downloadFileWithToken(fileName, path);
  };

  const prepareUserName = `${capitalize(
    attachment?.userFirstName
  )} ${capitalize(attachment?.userLastName)}`;

  return (
    <>
      <ModalPortal>
        <div
          className={styles.previewImageModal}
          onMouseDown={handleMouseDown}
          aria-hidden="true"
        >
          {!isSingleImage && (
            <IconButton
              icon={<ArrowLeftIcon />}
              appearance="flat"
              type="button"
              onClick={prevImage}
              onMouseDown={handleMouseDown}
              className={styles.previewImageModal__buttons_leftArrow}
            />
          )}
          <div className={styles.previewImageModal__container} ref={modalRef}>
            <div className={styles.previewImageModal__header}>
              <div className={styles.previewImageModal__headerLeft}>
                <Avatar
                  size={Size.xs}
                  className={styles.previewImageModal__headerLeftAvatar}
                  initialsUser={prepareUserName}
                />
                <div>
                  <div className={styles.previewImageModal__headerLeftUser}>
                    {prepareUserName}
                  </div>
                  <div className={styles.previewImageModal__headerLeftFile}>
                    <span
                      className={styles.previewImageModal__headerLeftFile_size}
                    >
                      {currentImage?.size}
                    </span>
                    <span>
                      {getDataCreateAttachment(attachment?.dateCreated)}
                    </span>
                  </div>
                </div>
              </div>
              <div className={styles.previewImageModal__headerRight}>
                <Typography
                  variant={TypographyVariants.b2}
                  className={styles.previewImageModal__headerRightNameFile}
                >
                  {currentImage?.fileName}
                </Typography>
                <div className={styles.previewImageModal__headerRightButtons}>
                  <IconButton
                    icon={<DownloadIcon />}
                    appearance="flat"
                    type="button"
                    onClick={(e) =>
                      handleDownloadFile({
                        e,
                        path: currentImage.path,
                        fileName: currentImage.fileName,
                      })
                    }
                    className={
                      styles.previewImageModal__headerRightButtons_download
                    }
                  />
                  <IconButton
                    icon={<CloseIcon />}
                    appearance="flat"
                    type="button"
                    onClick={toggleModal}
                    className={
                      styles.previewImageModal__headerRightButtons_close
                    }
                  />
                </div>
              </div>
            </div>
            <TransformWrapper initialScale={1} wheel={{ disabled: true }}>
              {({ zoomIn, zoomOut, resetTransform }) => (
                <>
                  <div className={styles.previewImageModal__controlButtons}>
                    <IconButton
                      icon={<ZoomOutIcon />}
                      appearance="flat"
                      type="button"
                      onClick={() => zoomOut(STEP_ZOOM)}
                      className={styles.previewImageModal__controlButtons_zoom}
                    />
                    <IconButton
                      icon={<ZoomInIcon />}
                      appearance="flat"
                      type="button"
                      onClick={() => zoomIn(STEP_ZOOM)}
                      className={styles.previewImageModal__controlButtons_zoom}
                    />
                    <Button
                      appearance="flat"
                      type="button"
                      onClick={() => resetTransform()}
                      className={styles.previewImageModal__controlButtons_reset}
                    >
                      СБРОС
                    </Button>
                  </div>
                  <TransformComponent
                    wrapperStyle={CUSTOM_WRAPPER_STYLE}
                    contentStyle={CUSTOM_CONTENT_STYLE}
                  >
                    <img
                      alt="main"
                      id="imgExample"
                      src={imageFiles[currentImageIndex]?.path}
                      className={styles.previewImageModal__image}
                    />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </div>
          <div>
            {!isSingleImage && (
              <IconButton
                icon={<ArrowRightIcon />}
                appearance="flat"
                type="button"
                onClick={nextImage}
                onMouseDown={handleMouseDown}
                className={styles.previewImageModal__buttons_rightArrow}
              />
            )}
          </div>
        </div>
      </ModalPortal>
    </>
  );
};
