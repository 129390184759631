import { Editor as EditorType } from '@ckeditor/ckeditor5-core';
import React, { ReactNode } from 'react';

import { ValueType } from 'components/Select/types';
import { CustomFieldType } from 'components/types';
import { KeyValueOption, UpdateContentType } from 'types/models/meta';

export type Nullable<T> = T | null;

export type NString = Nullable<string>;

export type NNumber = Nullable<number>;

export type NBoolean = Nullable<boolean>;

export enum ScreenWidth {
  MOBILE_SMALL = 'MOBILE_SMALL',
  MOBILE = 'MOBILE',
  TABLET_SMALL = 'TABLET_SMALL',
  TABLET = 'TABLET',
  DESKTOP_SMALL = 'DESKTOP_SMALL',
  DESKTOP = 'DESKTOP',
}

export enum Theme {
  LIGHT = 'light',
  DARK = 'dark',
  LIGHT_CONTRAST = 'light-contrast',
  DARK_CONTRAST = 'dark-contrast',
}

export enum ThemeActive {
  ON = 'on',
  OFF = 'off',
}

export enum AlertTypes {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}

export enum ActionForAlertTypes {
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
}

export enum OrganizationType {
  CUSTOMER = 'CUSTOMER',
  SERVICE = 'SERVICE',
  PARTNER = 'PARTNER',
}

export enum ContractType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  SUPPLEMENTARY_AGREEMENT = 'SUPPLEMENTARY_AGREEMENT',
}

export enum ContractStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'HIGH',
  PLANNED = 'LOW',
  NOT_SIGNED = 'NOT_SIGNED',
}

export enum SupportType {
  LICENSED = 'LICENSED',
  EXTENDED = 'EXTENDED',
}

export enum TicketStatus {
  CLOSE = 'CLOSE',
  NEW = 'NEW',
  PENDING_CLOSURE = 'PENDING_CLOSURE',
  WORK = 'WORK',
  WAITING_INFO = 'WAITING_INFO',
  REOPEN = 'REOPEN',
}

export enum FieldsType {
  organizations = 'organizations',
  systems = 'systems',
  ticketTypes = 'ticketTypes',
  ticketPriorities = 'ticketPriorities',
  environments = 'environments',
  clients = 'clients',
  status = 'status',
}

export enum RoleType {
  ROLE_IN_GROUP = 'ROLE_IN_GROUP',
  ROLE_IN_SYSTEM = 'ROLE_IN_SYSTEM',
}

export enum Priority {
  NONE = 'NONE',
  LOW = 'LOW',
  MIDDLE = 'MIDDLE',
  HIGH = 'HIGH',
  VERY_HIGH = 'VERY_HIGH',
}

export type Alert = {
  id: string;
  message: string | ReactNode;
  type: AlertTypes;
};

type CKEditorProps = {
  editor: EditorType;
  data: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config: any;
  onReady: (editor: EditorType) => void;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    editor: EditorType
  ) => void;
};

export type EditorRefType = {
  CKEditor: React.FC<CKEditorProps>;
  Editor: EditorType;
};

export type LoaderProps = {
  withLoader?: boolean;
  isLoading?: boolean;
};

// Attributes

export type Attribute = {
  id: string;
  isActive: boolean;
  attribute: ValueType<string>;
  value: ValueType<string>;
};

export type Attributes = {
  organizations: KeyValueOption[];
  systems: KeyValueOption[];
  environments: KeyValueOption[];
  clients: KeyValueOption[];
  ticketTypes: KeyValueOption[];
  ticketPriorities: KeyValueOption[];
};

// Action

export type ActionType = {
  id: string;
  title: string;
  nameInDatabase: string;
};

export type Action = ActionType & {
  accessCategory: ActionType;
  scope?: string[];
  uri: string[];
};

// Responsibility

export type Responsibility = {
  id: string;
  title?: string;
  description?: string;
  actionList?: Action[];
  organization?: Organization;
};

// Role

export type Role = {
  id: string;
  name: string;
  description: string;
  type: RoleType;
  manager?: boolean;
  responsibilityList?: Responsibility[];
  organization?: Omit<Organization, 'workGroupList' | 'contractList'>;
};

// User

export type User = {
  id: string;
  firstName: string;
  lastName?: string;
  middleName?: string;
  dateBirth?: string;
  email?: string;
  phoneNumber?: string;
  login?: string;
  password?: string;
  workGroupList?: WorkGroup[];
  description?: string;
  organization?: Organization;
  roles?: Role[];
  positions?: unknown;
  dateCreate?: string;
  work?: number;
  closed?: number;
  expired?: number;
  percentFromMaxDecision?: number;
  avgDecisionSeconds?: number;
  requireUpdatePassword?: boolean;
};

// WorkGroup

export type WorkGroup = {
  id: string;
  title: string;
  description: string;
  organizationId?: ValueType<string>;
  organizationTitle?: string;
  organization?: Organization;
  orgType?: string;
  orgDomain?: string;
  orgDescription?: string;
  orgAddress?: string;
  userList?: User[];
  responsibilityList?: Responsibility[];
  dateCreate?: string;
  organizationType?: OrganizationType;
  deleted?: boolean;
};

// Organization

export type Organization = {
  id?: string;
  title: string;
  type: OrganizationType;
  domain: string;
  description: string;
  address: string;
  workGroupList: WorkGroup[];
  contractList: Contract[];
  dateCreate?: string;
  emailTwoStepAuthIsEnable?: boolean;
};

export type OrganizationShort = Omit<
  Partial<Organization>,
  'type' | 'workGroupList' | 'contractList'
>;

export type OrganizationsFilter = {
  organizationTypes?: ValueType<OrganizationType> | OrganizationType[];
} & Partial<Pick<Organization, 'title' | 'domain' | 'description' | 'address'>>;

export interface OrganizationsFilterToRequest
  extends Omit<OrganizationsFilter, 'organizationTypes'> {
  organizationTypes?: OrganizationType[];
}

export type FetchOrganizations = {
  updateType: UpdateContentType;
} & Pick<OrganizationsFilterToRequest, 'organizationTypes'>;

export type OrganizationTypeForCustomField = Exclude<
  OrganizationType,
  OrganizationType.PARTNER
>;

export type Version = {
  id: string;
  version: string;
};

export type System = {
  id: string;
  title: string;
  description: string;
  index: string;
  contractList?: Contract[];
  environmentList: Environment[];
  typeList: TicketType[];
  organization?: Organization;
  organizationId?: ValueType<string>;
  version?: Version;
  versionId?: string;
  versionTitle?: string;
  deleted?: boolean;
  activeContract?: Contract;
};

export type SystemsFilterToRequest = {
  title?: string;
  description?: string;
  versionTitle?: string;
  organizationId?: string;
  deleted?: boolean | null;
  contractTitle?: string;
  withOnlyActiveContract?: boolean;
  ticketType?: TicketType[];
  environmentIds?: string[];
} & Pick<OrganizationsFilterToRequest, 'organizationTypes'>;

export type SystemsFilter = Omit<
  SystemsFilterToRequest,
  'organizationId' | 'environmentIds'
> & {
  organizationId?: ValueType<string>;
  environmentIds?: ValueType<string>;
};

// Contract

export type ContractNotificationConfig = {
  contractExpireIsEnable?: boolean;
  notifyBeforeExpire?: number;
};

export type ContractPermission = {
  storageAccessible?: boolean;
};

export type Contract = {
  id: string;
  title: string;
  description?: string;
  type: ContractType;
  status: ContractStatus;
  number?: string;
  openDate: string;
  endDate: string;
  organizationId?: string;
  organization?: OrganizationShort;
  organizationTitle?: string;
  partnerOrganization?: KeyValueOption;
  systemList?: System[];
  orgType?: string;
  orgDomain?: string;
  orgDescription?: string;
  orgAddress?: string;
  userList?: User[];
  workGroupList?: WorkGroup[];
  dateCreate?: string;
  systemTitle?: string;
  contractNotificationConfig?: ContractNotificationConfig;
  contractPermission?: ContractPermission;
  mainContractId?: string;
  supportType?: SupportType;
};

// Storage

export type StorageCustomField = {
  id?: string;
  title: string;
  description: string;
  type: CustomFieldType;
  storageId?: string;
  required?: boolean;
} & Partial<ValueCustomField>;

// CustomField

export type ValueCustomField = {
  text: NString;
  date: NString;
  flag: NBoolean;
};

export type CustomField = {
  id: string;
  title?: string;
  customFieldType?: CustomFieldType;
  organization?: Partial<Pick<Organization, 'id' | 'title'>>;
  accessType?: OrganizationTypeForCustomField;
  system?: Partial<Pick<System, 'id' | 'title'>>;
  required?: boolean;
};

export type CustomTicketFields = [
  CustomFieldToRequest[],
  CustomFieldToRequest[]
];

export type CustomFieldToRequest = CustomField &
  Partial<ValueCustomField> & {
    ticketId?: string;
    customFieldId?: string;
  };

// Ticket

export enum ProcedureType {
  CONSULTATION = 'CONSULTATION',
  INCIDENT = 'INCIDENT',
}

// Environments

export type Environment = {
  id: string;
  name: string;
  description: string;
  deleted?: boolean;
};

export type EnvironmentSetting = {
  active: boolean;
  environmentId: string;
  name: string;
};

export type EnvironmentSettingUpdate = {
  environmentId: string;
  active: boolean;
};

export type EnvironmentForSystem = {
  systemId: string;
  environmentId?: string;
};

export interface EnvironmentsWithStatus {
  environmentsWithStatus: EnvironmentSettingUpdate[];
}

export interface EnvironmentsFilter {
  id?: string;
  name?: string;
  systemId?: string;
  description?: string;
  isActive?: boolean;
}

// Status

export type Status = {
  id: string;
  name: string;
  isComment: boolean;
  isNotifications: boolean;
  isSLA: boolean;
  color: string;
  defaultStatus?: TicketStatus;
};

export type TicketStatusesRequest = {
  excludeStatuses?: TicketStatus[];
  systemIds?: string[];
  nextToIds?: string[];
  typeIds?: string[];
};

// TicketType

export type TicketType = {
  id: string;
  title: string;
  value: string;
};

export type TicketTypeSettings = {
  ticketTypeId: string;
  active?: boolean;
  title: string;
};
