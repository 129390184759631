import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TicketStatus } from 'core/types';
import {
  getIsAdmin,
  getIsClient,
  getIsGroupSpecialist,
  getIsManagerCurrentWorkGroups,
  getIsMyTicket,
  getUserId,
} from 'features/Auth/ducks/selectors';
import { ActionList } from 'features/Auth/types';
import {
  addSpecAndSetToWork,
  createTicketInJira,
  fetchNextStatusesRequest,
  restoreTicketRequest,
} from 'features/Ticket/ducks/ticket/actions';
import {
  getIsAccessedTicketAction,
  getIsSystemIntegratedWithJira,
  getJiraUrl,
  getTicket,
  getTicketWorkGroups,
} from 'features/Ticket/ducks/ticket/selectors';
import { useComponentDidMount } from 'hooks/useComponentDidMount';

import { useTicketId } from './useTicketId';

export const useTicket = () => {
  const ticketId = useTicketId();

  const isComponentMounted = useComponentDidMount();

  const ticket = useSelector(getTicket);
  const isClient = useSelector(getIsClient);
  const isAccessToWorkingWithMyGroupsTicketCards = useSelector(
    getIsAccessedTicketAction(ActionList.WorkingWithMyGroupsTicketCards)
  );
  const isMyTicket = useSelector(getIsMyTicket(ticket));
  const jiraUrl = useSelector(getJiraUrl);
  const isSystemIntegratedWithJira = useSelector(getIsSystemIntegratedWithJira);
  const userId = useSelector(getUserId);
  const isGroupSpecialist = useSelector(getIsGroupSpecialist);
  const ticketWorkGroups = useSelector(getTicketWorkGroups);
  const isManagerWorkGroup = useSelector(
    getIsManagerCurrentWorkGroups(ticketWorkGroups)
  );
  const isAdmin = useSelector(getIsAdmin);
  const isAccessToRestore = isAdmin || isManagerWorkGroup;

  const dispatch = useDispatch();

  const [isRestoreModal, setIsRestoreModal] = useState(false);

  const isNewTicket = ticket?.customStatus?.defaultStatus === TicketStatus.NEW;
  const isReopenTicket =
    ticket?.customStatus?.defaultStatus === TicketStatus.REOPEN;

  const isTakeToWorkDisabled =
    !isNewTicket && !isReopenTicket && Boolean(ticket?.specialistId);

  const isJiraButtonDisabled =
    isTakeToWorkDisabled ||
    !!jiraUrl ||
    !isSystemIntegratedWithJira ||
    isClient;

  const toggleRestoreModal = () => {
    setIsRestoreModal((prevState) => !prevState);
  };

  const setTicketToWorkHandler = () => {
    if (ticketId) {
      dispatch(addSpecAndSetToWork({ ticketId: [ticketId] }));
    }
  };

  const restoreTicketHandler = () => {
    dispatch(restoreTicketRequest(ticketId));
  };

  const createTicketInJiraHandler = () => {
    if (ticket?.systemId && ticket?.id && ticket?.ticketType && userId) {
      dispatch(
        createTicketInJira({
          systemId: ticket?.systemId.key,
          ticketId: ticket?.id,
          ticketTypeId: ticket?.ticketType?.key,
          userId,
        })
      );
    }
  };

  const setTicketToWorkAndCreateTaskInJiraHandler = (
    event?: React.MouseEvent
  ) => {
    event?.stopPropagation();
    createTicketInJiraHandler();
  };

  const isSeeTags = !isClient || isGroupSpecialist;

  const isEditTags =
    isSeeTags && (isAccessToWorkingWithMyGroupsTicketCards || isMyTicket);

  useEffect(() => {
    if (ticket?.customStatus?.id && ticket.clientId && isComponentMounted) {
      dispatch(
        fetchNextStatusesRequest({
          currentStatusId: ticket?.customStatus?.id,
          ticketCreatorId: ticket.clientId.key,
        })
      );
    }
  }, [ticket?.customStatus?.id]);

  return {
    isTakeToWorkDisabled,
    isJiraButtonDisabled,
    setTicketToWorkHandler,
    restoreTicketHandler,
    setTicketToWorkAndCreateTaskInJiraHandler,
    toggleRestoreModal,
    isSeeTags,
    isEditTags,
    isRestoreModal,
    isAccessToRestore,
  };
};
