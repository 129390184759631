import { Reducer } from 'redux';

import {
  Action,
  CustomFieldToRequest,
  Status,
  System,
  TicketType,
} from 'core/types';
import { Ticket, TicketHistory } from 'features/Ticket/types';
import { ReducerMap } from 'store/types';
import { KeyValueOption } from 'types/models/meta';

import {
  FetchActionsForTicketDetailSuccessAction,
  FetchCustomFieldsByTicketIdSuccessAction,
  FetchHistorySuccessAction,
  FetchIsSystemIntegratedWithJiraSuccessAction,
  FetchJiraUrlSuccessAction,
  FetchNextStatusesSuccessAction,
  FetchStatusesSuccessAction,
  SetIsErrorAction,
  SetIsSuccessAction,
  SetTicketDetailSuccessAction,
  SetTicketSuccessTypesActions,
  SetTicketSystemDetailSuccessAction,
  SetToastMessageAction,
  TicketActionsTypes,
  TicketDetail,
} from './types';

export interface TicketReducerState {
  ticket?: Ticket;
  ticketSystem?: System;
  loading?: boolean;
  history?: TicketHistory[];
  historyLoading?: boolean;
  isError?: boolean;
  isSuccess?: boolean;
  ticketActionsList?: Action[];
  isSystemIntegratedWithJira?: boolean;
  jiraUrl?: string;
  specialistCustomFields?: CustomFieldToRequest[];
  clientCustomFields?: CustomFieldToRequest[];
  nextStatuses?: Status[];
  ticketTypes?: TicketType[];
  statuses?: KeyValueOption[];
  toastMessage?: string;
}

const initialState: TicketReducerState = {
  loading: false,
  historyLoading: false,
  history: [],
  isError: false,
  isSuccess: false,
  ticketActionsList: [],
  isSystemIntegratedWithJira: false,
  specialistCustomFields: [],
  clientCustomFields: [],
  nextStatuses: [],
  ticketTypes: [],
  statuses: [],
};

const ticketReducerMap: ReducerMap<TicketReducerState, TicketActionsTypes> = {
  [TicketDetail.TICKET_DETAIL_LOADING_SHOW]: (state) => {
    return {
      ...state,
      loading: true,
    };
  },
  [TicketDetail.TICKET_DETAIL_LOADING_HIDE]: (state) => {
    return {
      ...state,
      loading: false,
    };
  },
  [TicketDetail.TICKET_HISTORY_LOADING_SHOW]: (state) => {
    return {
      ...state,
      historyLoading: true,
    };
  },
  [TicketDetail.TICKET_HISTORY_LOADING_HIDE]: (state) => {
    return {
      ...state,
      historyLoading: false,
    };
  },
  [TicketDetail.SET_TICKET_DETAIL_SUCCESS]: (state, action) => {
    const { payload } = action as SetTicketDetailSuccessAction;
    return {
      ...state,
      ticket: payload,
    };
  },
  [TicketDetail.FETCH_HISTORY_SUCCESS]: (state, action) => {
    const { payload } = action as FetchHistorySuccessAction;
    return {
      ...state,
      history: payload,
    };
  },
  [TicketDetail.SET_IS_ERROR]: (state, action) => {
    const { payload } = action as SetIsErrorAction;
    return {
      ...state,
      isError: payload,
    };
  },
  [TicketDetail.SET_IS_SUCCESS]: (state, action) => {
    const { payload } = action as SetIsSuccessAction;
    return {
      ...state,
      isSuccess: payload,
    };
  },
  [TicketDetail.SET_TOAST_MESSAGE]: (state, action) => {
    const { payload } = action as SetToastMessageAction;
    return {
      ...state,
      toastMessage: payload,
    };
  },
  [TicketDetail.FETCH_ACTIONS_FOR_TICKET_DETAIL_SUCCESS]: (state, action) => {
    const { payload } = action as FetchActionsForTicketDetailSuccessAction;
    return {
      ...state,
      ticketActionsList: payload,
    };
  },
  [TicketDetail.FETCH_IS_SYSTEM_INTEGRATED_WITH_JIRA_SUCCESS]: (
    state,
    action
  ) => {
    const { payload } = action as FetchIsSystemIntegratedWithJiraSuccessAction;
    return {
      ...state,
      isSystemIntegratedWithJira: payload,
    };
  },
  [TicketDetail.FETCH_JIRA_URL_SUCCESS]: (state, action) => {
    const { payload } = action as FetchJiraUrlSuccessAction;
    return {
      ...state,
      jiraUrl: payload,
    };
  },
  [TicketDetail.FETCH_CUSTOM_FIELDS_BY_TICKET_ID_SUCCESS]: (state, action) => {
    const { payload } = action as FetchCustomFieldsByTicketIdSuccessAction;
    const [clientCustomFields, specialistCustomFields] = payload;
    return {
      ...state,
      clientCustomFields,
      specialistCustomFields,
    };
  },
  [TicketDetail.FETCH_NEXT_STATUSES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchNextStatusesSuccessAction;
    return {
      ...state,
      nextStatuses: payload,
    };
  },
  [TicketDetail.RESET_TICKET_DETAIL_STATE]: () => {
    return {
      ...initialState,
    };
  },
  [TicketDetail.SET_TICKET_SYSTEM_DETAIL_SUCCESS]: (state, action) => {
    const { payload } = action as SetTicketSystemDetailSuccessAction;
    return {
      ...state,
      ticketSystem: payload,
    };
  },
  [TicketDetail.FETCH_TICKET_TYPES_SUCCESS]: (state, action) => {
    const { payload } = action as SetTicketSuccessTypesActions;
    return {
      ...state,
      ticketTypes: payload,
    };
  },
  [TicketDetail.FETCH_STATUSES_SUCCESS]: (state, action) => {
    const { payload } = action as FetchStatusesSuccessAction;
    return {
      ...state,
      statuses: payload,
    };
  },
};

export const ticket: Reducer<TicketReducerState, TicketActionsTypes> = (
  state = initialState,
  action
) => {
  const reducer = ticketReducerMap[action.type];
  if (!reducer) {
    return state;
  }
  return reducer(state, action);
};
