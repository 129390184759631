import { createSelector } from 'reselect';

import { SelectOption } from 'components/Select/types';
import { Organization, System, TicketType } from 'core/types';
import { Workflow } from 'core/types/workflow';
import { RootState } from 'store/rootReducer';

export const getSystems = (state: RootState): System[] | undefined =>
  state.statusesBuilder.systems;
export const getLoading = (state: RootState): boolean | undefined =>
  state.statusesBuilder.loading;
export const getPropsSystems = (state: RootState) => ({
  pageNum: state.statusesBuilder.pagination?.pageNum,
  pageSize: state.statusesBuilder.pagination?.pageSize,
  sortSystems: state.statusesBuilder.sort,
  totalElements: state.statusesBuilder.pagination?.totalElements,
  loadingSystem: getLoading(state),
});
export const getSort = (state: RootState) => state.statusesBuilder.sort;
export const getSystemId = (state: RootState) => state.statusesBuilder.systemId;
export const getSystemsFilter = (state: RootState) =>
  state.statusesBuilder.systemsFilter || {};
export const getOrganizations = (
  state: RootState
): Organization[] | undefined => state.statusesBuilder.organizations;
export const getWorkflow = (state: RootState): Workflow | undefined =>
  state.statusesBuilder.workflow;
export const getTicketTypes = (state: RootState) =>
  state.statusesBuilder.ticketTypesForSystem;

export const getTicketTypesSelectList = createSelector<
  RootState,
  TicketType[] | undefined,
  SelectOption[]
>([getTicketTypes], (types): SelectOption[] => {
  if (types) {
    return types.map(
      (type: TicketType): SelectOption => ({
        title: type.title,
        value: type.id,
      })
    );
  }
  return [];
});

export const getOrganizationsSelectList = createSelector<
  RootState,
  Organization[] | undefined,
  SelectOption[]
>([getOrganizations], (organizations): SelectOption[] => {
  if (organizations) {
    return organizations.map(
      (organization: Organization): SelectOption => ({
        title: organization.title,
        value: organization.id || '',
      })
    );
  }
  return [];
});

export const getSystemsSelectList = createSelector<
  RootState,
  System[] | undefined,
  SelectOption[]
>([getSystems], (systems): SelectOption[] => {
  if (systems) {
    return systems.map(
      (system: System): SelectOption => ({
        title: system.title,
        value: system.id || '',
      })
    );
  }
  return [];
});
