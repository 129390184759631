import cn from 'clsx';
import React, { forwardRef, ReactNode } from 'react';

import { TabItem } from '../types';

import styles from './RadioGroup.module.scss';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  items: TabItem[];
  className?: string;
  renderComponent: (title?: string) => ReactNode;
}

export const RadioGroup = forwardRef<HTMLInputElement, Props>(
  ({ className, items, onMouseDown, renderComponent, ...otherProps }, ref) => {
    const handleClick = (event: React.MouseEvent<HTMLInputElement>) => {
      event.preventDefault();
      if (onMouseDown) {
        onMouseDown(event);
      }
    };

    const itemsEls = items.map(({ title, value, disabled, defaultChecked }) => {
      return (
        <label
          key={String(value)}
          className={cn(styles.radioGroup__label, {
            [styles.radioGroup__label_disabled]: disabled,
          })}
        >
          <input
            ref={ref}
            type="radio"
            value={value}
            onMouseDown={handleClick}
            defaultChecked={defaultChecked}
            disabled={disabled}
            className={styles.radioGroup__input}
            {...otherProps}
          />
          {renderComponent(title)}
        </label>
      );
    });

    return <div className={cn(styles.radioGroup, className)}>{itemsEls}</div>;
  }
);
